<template>
  <BaseGuestRoomDailyReport>
    <template v-slot:default>
      <div class="pa-2">
        <v-card color="var(--bg__gray)" class="pa-4 mt-4">
          <v-row>
            <v-col :cols="9" class="flex-header pa-1">
              <v-btn @click="prevDay" icon color="#757575"
                ><v-icon size="35">mdi-chevron-double-left</v-icon></v-btn
              >
              <v-btn
                @click="nextDay"
                :disabled="new Date(date).getTime() > (new Date()).getTime()"
                icon
                color="#757575"
                ><v-icon size="35">mdi-chevron-double-right</v-icon></v-btn
              >
              <v-btn
                style="font-size:10px"
                class="mr-5 ml-1"
                color="#000000"
                outlined
                small
                @click="chooseToday"
              >
                {{ $t("common.toDay") }}
              </v-btn>

              <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <div class="custom-btn-picker">
                    <v-btn
                      outlined
                      v-on="on"
                      small
                      color="var(--text__gray)"
                      class="bg--btn-white mr-5"
                      >{{ formatDate(date) }}</v-btn
                    >
                  </div>
                </template>
                <v-date-picker
                  v-model="date"
                  :first-day-of-week="0"
                  :locale="$i18n.locale"
                  :max="maxDate"
                  scrollable
                  @input="getDate"
                  class="v-date-picker-custom"
                ></v-date-picker>
              </v-menu>
              <!--  -->

              <span class="font-weight-bold custom-text-fac" style="color: #000000">{{
                $t("common.facility")
              }}</span>
              <v-select
                v-model="facilitySelected"
                @change="changeFacility"
                :items="facilityList"
                item-text="name"
                item-value="id"
                placeholder=""
                dense
                small
                color="var(--text__gray)"
                outlined
                hide-details
                class="v-select-custom ml-1 facility-list"
              ></v-select>
            </v-col>
            <v-col :cols="3" class="d-flex justify-end flex-header pa-1">
              <v-btn
                @click="handleDailyUpdate"
                class="white--text custom-btn"
                color="var(--bt__red)"
                :disabled="(isReported && (reportType === 'TVS' || reportType === 'CNV')) || !checkUser"
              >
                {{ $t("reports.dailyUpdate") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <!-- ========= START TVS-Type =========-->
        <template v-if="reportType === 'TVS'">
          <div class="mt-4 ml-4 custom-size-title">
            <span>{{ $t("reports.cashCheck") }}</span>
          </div>
          <!-- START Report -->
          <v-card class="mt-4" max-width="1000px">
            <v-form ref="reportForm" lazy-validation>
              <v-card-text>
                <v-row class="pa-5 d-flex justify-space-between">
                  <v-col
                    v-for="(item, index) in moneyList"
                    :key="index"
                    cols="7"
                    sm="1"
                    class="pb-0"
                  >
                    <v-row>
                      <span
                        class="
                      text-size-normal
                      font-weight-bold
                      black--text
                      custom-size
                    "
                      >
                        {{ item.label }}
                      </span>
                    </v-row>
                    <v-row>
                      <v-text-field
                        @input="saveItem($event)"
                        v-model="item.value"
                        class="pa-0 small-message"
                        type="number"
                        :rules="rules.requiredNumber"
                        validate-on-blur
                        @keydown="checkNumber"
                        :disabled="isReported || !checkUser"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </v-row>
                <div class="mt-3 d-flex align-center">
                  <span
                    class="
                  text-size-normal
                  font-weight-bold
                  black--text
                  custom-size
                  mr-2
                "
                    >{{ $t("reports.moneyTotal") }}</span
                  >
                  ¥{{ getMoneyTotal() }}
                </div>
              </v-card-text>
              <v-card-text class="d-flex pt-2">
                <v-col
                  cols="2"
                  class="pl-0 text-size-normal custom-icon-select"
                >
                  <span class="text-size-normal black--text custom-size">{{
                    $t("reports.cashRegister")
                  }}</span>
                  <v-select
                    class="custom-select"
                    @input="saveItem($event)"
                    v-model="adjustmentSelected"
                    @change="changeAjustmentSelect"
                    :disabled="isReported || !checkUser"
                    :items="['無し', 'あり']"
                    hide-details
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <span
                    class="text-size-normal custom-size"
                    :class="{ 'text--inactive': adjustmentSelected === '無し' }"
                    >{{ $t("reports.adjustmentAmount") }}</span
                  >
                  <tvos-int-input
                    jpy
                    negative
                    @input="saveItem($event)"
                    dense
                    :disabled="
                      adjustmentSelected === '無し' || isReported || !checkUser
                    "
                    v-model="cashAdjustment"
                  >
                  </tvos-int-input>
                </v-col>
                <v-col cols="8" class="pr-0">
                  <span
                    class="text-size-normal custom-size"
                    :class="{ 'text--inactive': adjustmentSelected === '無し' }"
                    >{{ $t("reports.reason") }}</span
                  >
                  <v-text-field
                   @input="saveItem($event)"
                    dense
                    :rules="rules.text"
                    :disabled="
                      adjustmentSelected === '無し' || isReported || !checkUser
                    "
                    v-model="cashAdjustmentReason"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
              </v-card-text>
            </v-form>
          </v-card>
          <!-- END Report -->
          <div class="ml-4 mt-4 custom-size-title">
            <span>{{ $t("reports.dailySalesReport") }}</span>
          </div>
          <v-card class="mt-4" max-width="1000px">
            <v-data-table
              :headers="headers"
              :items="reportDetails"
              hide-default-footer
              :no-data-text="$t('rules.noData')"
              disable-pagination
              item-key="reportDate"
            >
              <template v-slot:[`item.amount`]="{ item }">
                {{ formatCurrency(item.amount) }}
              </template>
              <template v-slot:[`item.detail.check1stName`]="{ item }">
                <template v-if="checkItem(item)">
                  {{ (item.detail && item.detail.check1stName) || 'なし' }}
                </template>
              </template>
              <template v-slot:[`item.detail.check2ndName`]="{ item }">
                <template v-if="checkItem(item)">
                  {{ (item.detail && item.detail.check2ndName) || 'なし' }}
                </template>
              </template>
              <template v-slot:[`item.attachments`]="{ item }">
                <template v-if="checkItem(item) && checkDownloadItem(item)">
                  <v-btn
                    v-if="
                      item.detail &&
                        item.detail.attachments &&
                        item.detail.attachments.length > 0
                    "
                    class="mx-2"
                    color="#1873D9"
                    dark
                    fab
                    x-small
                    @click="
                      downloadFacilityDailyReportAttachmentFile(
                        item.paymentMethodId,
                        item.name
                      )
                    "
                  >
                    <v-icon dark size="18"> mdi-cloud-download </v-icon>
                  </v-btn>
                  <span v-else class="red-text">なし</span>
                </template>
              </template>
              <template v-slot:[`item.check`]="{ item }">
                <v-btn
                  v-if="checkItem(item)"
                  class="mx-2"
                  color="#1873D9"
                  dark
                  fab
                  x-small
                  :disabled="!checkUser"
                  @click="checkDialog(item)"
                >
                  <v-icon
                    dark
                    size="18"
                  >
                    mdi-account-check
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
          <v-card class="mt-4" max-width="1000px">
            <span
              class="ml-4 font-weight-bold mt-5 text-size-normal custom-size"
            >
              {{ $t("reports.memoComment") }}
            </span>
            <v-textarea
              ref="reportNote"
              class="pb-4 report-note"
              dense
              rounded
              @input="saveItem($event)"
              :rules="rules.reportNote"
              :readonly="isReported || !checkUser"
              v-model="reportNote"
              validate-on-blur
            ></v-textarea>
          </v-card>
          <VoucherCheckDialog
            :isReported="isReported"
            :visible="visible"
            :date="date"
            :facilitySelected="facilitySelected"
            :itemReport="itemReport"
            @close="visible = false"
            @reloadData="getRecordedIncomeData"
          />
          <CheckCreditCardDialog
            v-if="checkCreditCardVisible"
            :key="idx"
            :isReported="isReported"
            :facilitySelected="facilitySelected"
            :date="date"
            :itemReport="itemReport"
            :visible="checkCreditCardVisible"
            @close="checkCreditCardVisible = false"
            @reloadData="getRecordedIncomeData"
          />
        </template>
        <!--========= END TVS-Type =========-->

        <!--========= START CNV-Type =========-->
        <template v-else>
          <h2 class="pa-4 mt-4 custom-size-title">
            ホテルコンバージョン施設日報
          </h2>
          <div class="d-flex align-center">
            <label class="mr-2 mb-2 custom-size font-weight-bold"
              >ステータス</label
            >
            <v-select
              class="mt-0 pa-0 v-select-custom mb-2"
              hide-details
              outlined
              :items="statusSelectList"
              v-model="statusSelected"
            />
          </div>
          <v-data-table
            class="elevation-1 table-custom --cnv"
            dense
            :headers="cnvReportTableHeader"
            :items="cnvReportDataFiltered"
            :item-class="itemRowBackground"
            :no-results-text="$t('rules.noData')"
            :no-data-text="$t('rules.noData')"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:[`item.cleaning`]="{ item }">
              <v-menu
                offset-y
                max-width="150"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                  <template v-if="item.facilityDailyReportConversionDetail.cleaning">
                    {{
                      item.facilityDailyReportConversionDetail.cleaning ===
                      "Yes"
                        ? "あり"
                        : "なし"
                    }}
                  </template>
                  <span v-else class="text--red-dark">
                    未選択
                  </span>
                  </v-btn>
                </template>
                <v-card class="px-4" v-if="checkUser">
                  <cleaning-yes-no
                    @change="saveEventCNV"
                    v-model="item.facilityDailyReportConversionDetail.cleaning"
                  />
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.cleaningFeeExempt`]="{ item }">
              <v-menu
                offset-y
                max-width="150"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      v-if="
                        item.facilityDailyReportConversionDetail.cleaningFeeExempt
                      "
                      class="text--prm"
                    >
                      {{
                        item.facilityDailyReportConversionDetail
                          .cleaningFeeExempt === "Clients"
                          ? "顧客"
                          : "顧客と施設"
                      }}
                    </span>
                    <span v-else class="text--inactive">
                      未選択
                    </span>
                  </v-btn>
                </template>
                <v-card class="px-4">
                  <v-select
                    :items="cleaningFeeExemptList"
                    @change="saveEventCNV"
                    v-model="item.facilityDailyReportConversionDetail.cleaningFeeExempt"
                    item-text="name"
                    item-value="value"
                    class="cleaning-select"
                  >
                  </v-select>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.cleaningFeeExemptAmount`]="{ item }">
              <v-menu
                offset-y
                max-width="170"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      v-if="
                        item.facilityDailyReportConversionDetail
                          .cleaningFeeExemptAmount
                      "
                      class="text--prm"
                    >
                      {{
                        currency2(item.facilityDailyReportConversionDetail
                          .cleaningFeeExemptAmount)
                      }}
                    </span>
                    <span v-else class="text--inactive">
                      未記入
                    </span>
                  </v-btn>
                </template>
                <v-card class="px-4 pt-4 pb-1">
                  <v-text-field
                    placeholder="清掃免除金額"
                    class="small-message"
                    :rules="rules.numberNotRequired"
                    type="number"
                    @input="checkCleaningFeeExemptAmount($event, item), saveEventCNV($event)"
                    @keydown="checkNumber"
                    outlined
                    dense
                    :value="item.facilityDailyReportConversionDetail.cleaningFeeExemptAmount"
                  >
                  </v-text-field>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.facilityFeeExempt`]="{ item }">
              <v-menu
                offset-y
                max-width="150"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      v-if="
                        item.facilityDailyReportConversionDetail.facilityFeeExempt
                      "
                      class="text--prm"
                    >
                      {{
                        item.facilityDailyReportConversionDetail
                          .facilityFeeExempt === "Clients"
                          ? "顧客"
                          : "顧客と施設"
                      }}
                    </span>
                    <span v-else class="text--inactive">
                      未選択
                    </span>
                  </v-btn>
                </template>
                <v-card class="px-4">
                  <v-select
                    :items="cleaningFeeExemptList"
                    @change="saveEventCNV"
                    v-model="item.facilityDailyReportConversionDetail.facilityFeeExempt"
                    item-text="name"
                    item-value="value"
                    class="cleaning-select"
                  >
                  </v-select>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.facilityFeeExemptAmount`]="{ item }">
              <v-menu
                offset-y
                max-width="170"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      v-if="
                        item.facilityDailyReportConversionDetail
                          .facilityFeeExemptAmount
                      "
                      class="text--prm"
                    >
                      {{
                        currency2(item.facilityDailyReportConversionDetail
                          .facilityFeeExemptAmount)
                      }}
                    </span>
                    <span v-else class="text--inactive">
                      未記入
                    </span>
                  </v-btn>
                </template>
                <v-card class="px-4 pt-4 pb-1">
                  <v-text-field
                    placeholder="利用料免除金額"
                    class="small-message"
                    :rules="rules.numberNotRequired"
                    type="number"
                    @input="checkFacilityFeeExemptAmount($event, item), saveEventCNV($event)"
                    @keydown="checkNumber"
                    outlined
                    dense
                    :value="item.facilityDailyReportConversionDetail.facilityFeeExemptAmount"
                  >
                  </v-text-field>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.remarks`]="{ item }">
              <v-menu
                offset-y
                max-width="170"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      v-if="item.facilityDailyReportConversionDetail.remarks"
                      class="text--prm"
                    >
                      {{ item.facilityDailyReportConversionDetail.remarks }}
                    </span>
                    <span v-else class="text--inactive">
                      未記入
                    </span>
                  </v-btn>
                </template>
                <v-card class="px-4 pt-4 pb-1">
                  <v-text-field
                    placeholder="対応方法・理由"
                    class="small-message"
                    :rules="rules.remark"
                    outlined
                    dense
                    @input="checkRemarks($event, item), saveEventCNV($event)"
                    :value="item.facilityDailyReportConversionDetail.remarks"
                  >
                  </v-text-field>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-btn
                outlined
                small
                class="bg--white"
                :disabled="
                  !checkUser || !item.facilityDailyReportConversionDetail
                "
                target="_blank"
                :href="$goToRoute.booking.url(item.id)"
                color="var(--bt__blue)"
              >
                {{ $t("buttons.edit") }}
              </v-btn>
            </template>
            <template v-slot:[`item.computed`]="{ item }">
              {{ item.computed && item.computed.status }}
            </template>
            <template v-slot:[`item.checkInDate`]="{ item }">
              {{ item.checkInDate.split("-").join("/") }}
            </template>
            <template v-slot:[`item.checkOutDate`]="{ item }">
              {{ item.checkOutDate.split("-").join("/") }}
            </template>
            <template v-slot:[`item.representative`]="{ item }">
              {{ item.representative && item.representative.name }}
            </template>
          </v-data-table>

          <v-card class="mt-6 py-3" max-width="1000px">
            <label class="ml-5 font-weight-bold custom-size">
              {{ $t("reports.memoComment") }}
            </label>
            <v-textarea
              ref="reportCnvNote"
              class="mt-2"
              dense
              rounded
              :rules="rules.reportNote"
              :readonly="isReported || !checkUser"
              v-model="reportCnvNote"
              validate-on-blur
            ></v-textarea>
          </v-card>
        </template>
        <!--========= END CNV-Type =========-->
      </div>
    </template>
  </BaseGuestRoomDailyReport>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions'
import BaseGuestRoomDailyReport from '../../../components/GuestRoomDailyReport/BaseGuestRoomDailyReport'
import VoucherCheckDialog from './voucherCheckDialog'
import CheckCreditCardDialog from './checkCreditCardDialog'
import { formatDate, formatCurrency, handlError, getCopyErrorTextView } from '@/constants/functions'
import { nextOrPrevDay } from '@/utils/handleDate'
import { showCheckIcon } from '@/utils/dailyReport'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import {
  GET_RECORDED_INCOME_FACILITY_DAY,
  SUBMIT_TVS_FACILITY_DAILY_REPORT,
  SUBMIT_CNV_FACILITY_DAILY_REPORT
} from '@/api/graphql/guestRoomDailyReport/get-recorded-income-facility-day'
import { MAX_INT } from '@/constants/enum'
import { listContractBackground } from '@/constants/contractColors'
import { mapMutations } from 'vuex'
import { currency2 } from '@/utils/filters.js'
import gql from 'graphql-tag'
import CleaningYesNo from './components/CleaningYesNo.vue'

const SEARCH_ALL = '全て'

export default {
  name: 'DailyReport',
  data () {
    return {
      maxDate: '2022-01-01',
      arrSaveOnly: [],
      arrSaveOnlyCsv: [],
      arrSaveItemCsv: [],
      arrSaveItem: [],
      idx: 0,
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      itemReport: {},
      menuDate: false,
      facilityList: [],
      facilitySelected: null,
      date: null,
      reportType: null, // Check 'report > type' to choose screen ( TVS or CNV)

      // START TVS-screen ----------------------------------------
      isReported: false, // Check report

      cashAdjustmentReason: null,
      checkCreditCardVisible: false,
      creditTitle: '',
      creditDay: '',
      creditFirstConfirmer: '',
      creditSecondConfirmer: '',
      visible: false,
      moneyList: [
        { value: null, label: '1円玉' },
        { value: null, label: '5円玉' },
        { value: null, label: '10円玉' },
        { value: null, label: '50円玉' },
        { value: null, label: '100円玉' },
        { value: null, label: '500円玉' },
        { value: null, label: '1000円札' },
        { value: null, label: '2000円札' },
        { value: null, label: '5000円札' },
        { value: null, label: '10000円札' }
      ],
      reportDetails: [],
      reportNote: null,
      paymentMethodId: null,
      adjustmentSelected: '無し',
      cashAdjustment: 0,
      headers: [
        { text: this.$t('reports.kindName'), value: 'name' },
        { text: this.$t('reports.onTheDay'), value: 'amount' },
        {
          text: this.$t('reports.primaryConfirmer'),
          value: 'detail.check1stName'
        },
        {
          text: this.$t('reports.secondaryConfirmer'),
          value: 'detail.check2ndName'
        },
        {
          text: this.$t('common.fileDownload'),
          align: 'center',
          value: 'attachments'
        },
        {
          text: this.$t('reports.dailyCheck'),
          align: 'center',
          value: 'check'
        }
      ],
      // END TVS-screen ----------------------------------------

      // START CNV-screen --------------------------------------
      keySearch: null,
      cleaningFeeExemptList: [
        {
          name: '顧客',
          value: 'Clients'
        },
        {
          name: '顧客と施設',
          value: 'ClientsAndFacilities'
        },
        {
          name: '未選択',
          value: null
        }
      ],
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      statusSelected: SEARCH_ALL,
      reportCnvNote: null,
      cnvReportData: [],
      cnvReportTableHeader: [
        {
          text: '清掃有無',
          value: 'cleaning'
        },
        {
          text: '清掃料免除区分',
          value: 'cleaningFeeExempt'
        },
        {
          text: '清掃免除金額',
          value: 'cleaningFeeExemptAmount'
        },
        {
          text: '利用料免除区分',
          value: 'facilityFeeExempt'
        },
        {
          text: '利用料免除金額',
          value: 'facilityFeeExemptAmount'
        },
        {
          text: '対応方法・理由',
          value: 'remarks'
        },
        {
          text: '予約編集',
          value: 'edit'
        },
        {
          text: '部屋',
          value: 'room.name'
        },
        {
          text: '代表者',
          value: 'representative'
        },
        {
          text: '予約ID',
          value: 'id'
        },
        {
          text: 'ステータス',
          value: 'computed'
        },
        {
          text: '部屋タイプ',
          value: 'room.roomType.name'
        },
        {
          text: 'チェックイン',
          value: 'checkInDate'
        },
        {
          text: 'チェックアウト',
          value: 'checkOutDate'
        }
      ],
      // END CNV-screen ----------------------------------------
      rules: {
        numberNotRequired: [
          v => (v >= 0 && v < MAX_INT) || !v || this.$t('rules.isInvalid')
        ],
        remark: [
          v => v?.length < 250 || !v || this.$t('rules.isInvalid')
        ],
        cashAdjustment: [
          v => (v >= -MAX_INT && v < MAX_INT) || this.$t('rules.isInvalid')
        ],
        requiredNumber: [
          v =>
            (!!v && v < MAX_INT) ||
            v === 0 ||
            this.$t('rules.invalid', { title: '' })
        ],
        reportNote: [v => v?.length < 2001 || !v || this.$t('rules.isInvalid')],
        text: [v => v?.length < 256 || !v || this.$t('rules.isInvalid')]
      }
    }
  },
  async mounted () {
    await this.getFacilityList()
    await this.getRecordedIncomeData()
  },
  computed: {
    statusSelectList () {
      const statuses = this.cnvReportData.map(item => item.computed.status).sort()
      statuses.unshift(SEARCH_ALL)
      return statuses
    },
    cnvReportDataFiltered () {
      return this.cnvReportData.filter(item => this.statusSelected === SEARCH_ALL || item.computed.status === this.statusSelected)
    }
  },
  watch: {
    async date () {
      try {
        await this.getRecordedIncomeData()
      } finally {
        if (this.date && this.maxDate && this.maxDate < this.date) {
          this.date = this.maxDate
        }
      }
    }
  },
  methods: {
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setAlertSuccess',
      'setLoadingOverlayHide',
      'setLoadingOverlayShow'
    ]),
    getNumOfPage (pagination) {
      this.numLength = pagination.itemsLength
      this.itemStart = pagination.pageStart + 1
      this.itemStop = pagination.pageStop
    },
    currency2,
    formatCurrency,
    formatDate,
    nextOrPrevDay,

    saveEventCNV () {
      const data = {
        date: this.date,
        cnvReportData: this.cnvReportData
      }
      this.arrSaveItemCsv.push(data)
      const arrayId = []
      const B = []
      for (let i = this.arrSaveItemCsv.length - 1; i >= 0; i--) {
        if (!arrayId.includes(this.arrSaveItemCsv[i].date)) {
          arrayId.push(this.arrSaveItemCsv[i].date)
          B.push(this.arrSaveItemCsv[i])
        }
      }
      this.arrSaveOnlyCsv = B
    },

    saveItem (event) {
      const data = {
        date: this.date,
        item: JSON.parse(JSON.stringify(this.moneyList)),
        reportNote: this.reportNote,
        adjustmentSelected: this.adjustmentSelected,
        cashAdjustmentReason: this.cashAdjustmentReason,
        cashAdjustment: JSON.parse(JSON.stringify(this.cashAdjustment))
      }
      this.arrSaveItem.push(data)
      const arrayId = []
      const B = []
      for (let i = this.arrSaveItem.length - 1; i >= 0; i--) {
        if (!arrayId.includes(this.arrSaveItem[i].date)) {
          arrayId.push(this.arrSaveItem[i].date)
          B.push(this.arrSaveItem[i])
        }
      }
      this.arrSaveOnly = B
    },

    checkItem (item) {
      if (showCheckIcon(item)) {
        return true
      }
      return false
    },
    checkDownloadItem (item) {
      if (
        item.category === '商品券' ||
        item.paymentMethodId === 61 ||
        item.paymentMethodId === 62
      ) {
        return false
      }
      return true
    },
    getDate (v) {
      this.menuDate = false
    },
    async handleDailyUpdate () {
      if (this.reportType === 'TVS' && !this.isReported) {
        if (
          this.$refs.reportForm.validate() &&
          this.$refs.reportNote.validate()
        ) {
          await this.submitTVSFacilityDailyReport()
          this.isReported = true
          this.$refs.reportForm.resetValidation()
          this.$refs.reportNote.resetValidation()
        }
      } else if (
        this.reportType === 'CNV' &&
        !this.isReported &&
        this.$refs.reportCnvNote.validate()
      ) {
        await this.submitCNVFacilityDailyReport()
        this.isReported = true
        this.$refs.reportCnvNote.resetValidation()
      }
    },
    changeAjustmentSelect (v) {
      if (v === '無し') {
        this.cashAdjustment = 0
      }
    },
    prevDay () {
      const date = this.nextOrPrevDay(this.date, -1)
      this.date = date
    },
    nextDay () {
      const date = this.nextOrPrevDay(this.date, 1)
      this.date = date
    },
    chooseToday () {
      this.date = this.$today()
    },
    changeFacility (v) {
      this.reportType = this.facilityList.find(item => item.id === v).type
      this.getRecordedIncomeData()
    },

    async getFacilityList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${FACILITY_LIST}`
        })
        .then(data => {
          const facilityList = data.data.facilityList.filter(
            item => item.type === 'TVS' || item.type === 'CNV'
          )
          if (this.checkUser) {
            this.facilityList = facilityList.sort((a, b) => b.id - a.id)
          } else {
            this.facilityList = facilityList
          }
          this.facilitySelected = this.facilityList[0].id
          this.reportType = data.data.facilityList.find(
            item => item.id === this.facilityList[0].id
          ).type
          this.setLoadingOverlayHide()
        })
        .catch(error => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },
    async getRecordedIncomeData () {
      this.cnvReportData = []
      this.statusSelected = SEARCH_ALL
      this.setLoadingOverlayShow()
      await this.$showGqlError(this.$apollo
        .query({
          query: gql`${GET_RECORDED_INCOME_FACILITY_DAY}`,
          variables: {
            facilityId: parseInt(this.facilitySelected),
            reportDate: this.date
          },
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          const reportData = data.data.getRecordedIncomeFacilityDay.report
          // set the date of the report when read
          this.date = reportData.reportDate
          if (this.reportType === 'TVS') {
            if (!reportData.facilitySubmittedAt || reportData.hqRejectedAt) {
              this.isReported = false
            } else {
              this.isReported = true
            }
            if (data.data.getRecordedIncomeFacilityDay.details) {
              this.reportDetails =
                data.data.getRecordedIncomeFacilityDay.details
            } else {
              this.reportDetails = []
            }

            this.reportType = reportData.type

            const items = this.arrSaveOnly.find(i => i.date === this.date)

            if (typeof items === 'undefined') {
              this.cashAdjustmentReason = reportData.cashAdjustmentReason
              if (reportData.cashAdjustment !== 0) {
                this.cashAdjustment = reportData.cashAdjustment
                this.adjustmentSelected = 'あり'
              } else {
                this.cashAdjustment = 0
                this.adjustmentSelected = '無し'
              }
              this.reportNote = reportData.notes
              this.moneyList[0].value = reportData.cash1
              this.moneyList[1].value = reportData.cash5
              this.moneyList[2].value = reportData.cash10
              this.moneyList[3].value = reportData.cash50
              this.moneyList[4].value = reportData.cash100
              this.moneyList[5].value = reportData.cash500
              this.moneyList[6].value = reportData.cash1000
              this.moneyList[7].value = reportData.cash2000
              this.moneyList[8].value = reportData.cash5000
              this.moneyList[9].value = reportData.cash10000
            } else {
              this.cashAdjustmentReason = items.cashAdjustmentReason
              this.adjustmentSelected = items.adjustmentSelected
              if (this.adjustmentSelected === 'あり') {
                this.cashAdjustment = items.cashAdjustment
              } else this.cashAdjustment = 0
              this.reportNote = items.reportNote
              this.moneyList[0].value = items.item[0].value
              this.moneyList[1].value = items.item[1].value
              this.moneyList[2].value = items.item[2].value
              this.moneyList[3].value = items.item[3].value
              this.moneyList[4].value = items.item[4].value
              this.moneyList[5].value = items.item[5].value
              this.moneyList[6].value = items.item[6].value
              this.moneyList[7].value = items.item[7].value
              this.moneyList[8].value = items.item[8].value
              this.moneyList[9].value = items.item[9].value
            }
          } else {
            if (!reportData.facilitySubmittedAt || reportData.hqRejectedAt) {
              this.isReported = false
            } else {
              this.isReported = true
            }

            const dataCnv = this.arrSaveOnlyCsv.find(i => i.date === this.date)
            if (typeof dataCnv !== 'undefined') {
              this.cnvReportData = this.arrSaveOnlyCsv.find(i => i.date === this.date).cnvReportData
            } else {
              this.$refs.reportCnvNote.resetValidation()
              this.reportCnvNote =
                data.data.getRecordedIncomeFacilityDay.report.notes
              const cnvData = data.data.getRecordedIncomeFacilityDay
              if (cnvData.bookings?.length > 0) {
                cnvData.bookings.map(item => {
                  if (!item.facilityDailyReportConversionDetail) {
                    this.cnvReportData.push({
                      ...item,
                      facilityDailyReportConversionDetail: {
                        cleaning: 'No',
                        cleaningFeeExempt: null,
                        cleaningFeeExemptAmount: null,
                        facilityFeeExempt: null,
                        facilityFeeExemptAmount: null,
                        remarks: null
                      }
                    })
                  } else {
                    this.cnvReportData.push(item)
                  }
                })
              }
            }
          }
          this.setLoadingOverlayHide()
        })
        .catch(error => {
          this.isReported = false
          this.setLoadingOverlayHide()
          throw error
        })
      )
    },
    async afterSubmitReport () {
      await this.$apollo.queries.maxDate.refetch()
    },
    async submitTVSFacilityDailyReport () {
      this.setLoadingOverlayShow()
      await this.$showGqlError(this.$apollo
        .mutate({
          mutation: gql`${SUBMIT_TVS_FACILITY_DAILY_REPORT}`,
          variables: {
            facilityId: parseInt(this.facilitySelected),
            reportDate: this.date,
            cash1Count: parseInt(this.moneyList[0].value),
            cash5Count: parseInt(this.moneyList[1].value),
            cash10Count: parseInt(this.moneyList[2].value),
            cash50Count: parseInt(this.moneyList[3].value),
            cash100Count: parseInt(this.moneyList[4].value),
            cash500Count: parseInt(this.moneyList[5].value),
            cash1000Count: parseInt(this.moneyList[6].value),
            cash2000Count: parseInt(this.moneyList[7].value),
            cash5000Count: parseInt(this.moneyList[8].value),
            cash10000Count: parseInt(this.moneyList[9].value),
            cashAdjustment: parseInt(this.cashAdjustment),
            cashAdjustmentReason: this.cashAdjustmentReason,
            notes: this.reportNote
          }
        })
        .then(data => {
          this.setLoadingOverlayHide()
          this.setAlertSuccess(this.$t('messages.successfulUpdate'))
        })
        .catch(error => {
          this.setLoadingOverlayHide()
          throw error
        }))
      await this.afterSubmitReport()
    },
    async submitCNVFacilityDailyReport () {
      this.setLoadingOverlayShow()
      const arr = []
      this.cnvReportData.length > 0 && this.cnvReportData.map(item => {
        item.facilityDailyReportConversionDetail &&
          arr.push({
            bookingId: parseInt(item.id),
            cleaning: item.facilityDailyReportConversionDetail.cleaning,
            cleaningFeeExempt:
              item.facilityDailyReportConversionDetail.cleaningFeeExempt,
            cleaningFeeExemptAmount:
              parseInt(item.facilityDailyReportConversionDetail
                .cleaningFeeExemptAmount),
            facilityFeeExempt:
              item.facilityDailyReportConversionDetail.facilityFeeExempt,
            facilityFeeExemptAmount:
              parseInt(item.facilityDailyReportConversionDetail
                .facilityFeeExemptAmount),
            remarks: item.facilityDailyReportConversionDetail.remarks
          })
      })
      const variables = {
        facilityId: parseInt(this.facilitySelected),
        reportDate: this.date,
        detail: arr,
        note: this.reportCnvNote
      }
      await this.$apollo
        .mutate({
          mutation: gql`${SUBMIT_CNV_FACILITY_DAILY_REPORT}`,
          variables: variables
        })
        .then(data => {
          this.setLoadingOverlayHide()
          this.setAlertSuccess(this.$t('messages.successfulUpdate'))
        })
        .catch(error => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(SUBMIT_CNV_FACILITY_DAILY_REPORT, variables, error.graphQLErrors))
          handlError(this.$store.commit, error.graphQLErrors)
        })
      await this.afterSubmitReport()
    },
    async downloadFacilityDailyReportAttachmentFile (paymentMethodId, name) {
      await this.$store.dispatch('downloadDailyReportAttachment', {
        facilityId: this.facilitySelected,
        reportDate: this.date,
        paymentMethodId,
        name
      })
    },
    getMoneyTotal () {
      const cash1 = this.moneyList[0].value || 0
      const cash5 = this.moneyList[1].value || 0
      const cash10 = this.moneyList[2].value || 0
      const cash50 = this.moneyList[3].value || 0
      const cash100 = this.moneyList[4].value || 0
      const cash500 = this.moneyList[5].value || 0
      const cash1000 = this.moneyList[6].value || 0
      const cash2000 = this.moneyList[7].value || 0
      const cash5000 = this.moneyList[8].value || 0
      const cash10000 = this.moneyList[9].value || 0
      const moneyTotal =
        parseInt(cash1) +
        parseInt(cash5) * 5 +
        parseInt(cash10) * 10 +
        parseInt(cash50) * 50 +
        parseInt(cash100) * 100 +
        parseInt(cash500) * 500 +
        parseInt(cash1000) * 1000 +
        parseInt(cash2000) * 2000 +
        parseInt(cash5000) * 5000 +
        parseInt(cash10000) * 10000
      const result = this.formatCurrency(moneyTotal)
      return result
    },
    checkDialog (item) {
      this.itemReport = item
      if (item.category === '商品券') {
        this.paymentMethodId = item.paymentMethodId
        this.visible = true
      } else if (item.category === 'クレジットカード') {
        this.idx = this.idx + 1
        this.checkCreditCardVisible = true
      }
    },
    checkCleaningFeeExemptAmount (e, item) {
      if (e < MAX_INT) {
        item.facilityDailyReportConversionDetail.cleaningFeeExemptAmount = e
      }
      this.$forceUpdate()
    },
    checkFacilityFeeExemptAmount (e, item) {
      if (e < MAX_INT) {
        item.facilityDailyReportConversionDetail.facilityFeeExemptAmount = e
      }
      this.$forceUpdate()
    },
    checkRemarks (e, item) {
      if (e?.length < 250) {
        item.facilityDailyReportConversionDetail.remarks = e
      }
      this.$forceUpdate()
    },
    checkNumber (evt) {
      if (['e', 'E', '+', '-'].includes(evt.key)) {
        return evt.preventDefault()
      }
    },
    itemRowBackground (item) {
      if (item.blockRoom) {
        return 'bg-contract--block'
      }
      let contract = item.bookingType.code.trim()
      if (contract.includes('SP')) {
        contract = 'SP'
      }
      return listContractBackground[contract]
    }
  },
  components: {
    BaseGuestRoomDailyReport,
    CheckCreditCardDialog,
    VoucherCheckDialog,
    CleaningYesNo
  },
  apollo: {
    maxDate: {
      query: gql`
        query ($facilityId: Int!) {
          nextDailyReportDate(facilityId:$facilityId) {
            reportDate
          }
        }
      `,
      skip () {
        return !this.facilitySelected
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result (result) {
        if (result.errors && result.errors.length) {
          handlError(this.$store.commit, result.errors)
          return
        }
        this.maxDate = result.data.nextDailyReportDate.reportDate
        if (this.maxDate < this.date) {
          this.date = this.maxDate
        }
      },
      variables () {
        return { facilityId: this.facilitySelected }
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-select__selection--comma {
    color: #000000 !important;
  }
  &.v-data-table {
    th{
      font-size: 14px !important;
      color: #757575 !important;
      padding-right: 0 !important;
    }
    td {
      padding-right: 0 !important;
    }
  }
  .cleaning-select {
    .v-select__selections * {
      font-size: 14px !important;
    }
  }
  .table-custom {
    thead * {
      color: #757575 !important;
    }
    tbody > tr > td {
      font-size: 14px !important;
      max-width: 200px !important;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      overflow: hidden;
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        font-weight: bold !important;
      }
    }
  }
  .small-message {
    .v-messages__message {
      font-size: 11px !important;
      line-height: 1.5 !important;
    }
  }
  .theme--light.v-input textarea {
    border: 2px solid rgba(0, 0, 0, 0.16) !important;
    border-radius: 7px;
    padding-left: 8px !important;
  }
  .report-note {
    .v-text-field__details {
      margin-left: 24px !important;
      margin-top: 2px !important;
    }
  }
  .v-select__selection--comma {
    font-size: 10px !important;
  }
  .custom-select .v-select__selection--comma {
    font-size: 14px !important;
    color: #444444;
  }
  .custom-btn-picker .v-btn__content {
    font-size: 10px;
    color: #000000;
  }
  .custom-btn .v-btn__content {
    font-size: 14px;
    color: #ffffff !important;
  }
  .custom-icon-select .v-icon {
    color: #333333;
  }
}
.custom-size {
  color: #000000;
  font-size: 10px !important;
}
.custom-text-fac {
  font: 12px Noto Sans;
}
.custom-size-title {
  font-size: 20px !important;
  color: #000000;
  font: normal normal bold 20px/30px Noto Sans;
}
.red-text {
  color: #ff0000;
}
</style>
