<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog" width="500">
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <span class="title-dialog">
              {{ voucherName }} チェック
            </span>
          </v-card-title>
          <v-card-text class="pb-0">
            <span class="cus-title-item">{{ $t("common.amountOfMoney") }}</span>
            <div class="pt-2">
              <p class="custom-value-input">{{ formatCurrency(amount) }}</p>
            </div>
          </v-card-text>
          <div class="d-flex pd-12">
            <v-col cols="8">
              <v-text-field
                type="number"
                v-model="voucher1Amount"
                class="cus-title-item"
                :label="$t('common.amountOfMoney')"
                dense
                :disabled="isReported"
                @keydown="checkNumber"
                :rules="rules.number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="voucher1Count"
                :items="listItemCount"
                :label="$t('common.numberOfSheets')"
                hide-details
                :disabled="isReported"
                dense
              >
              </v-select>
            </v-col>
          </div>
          <div class="d-flex pd-12">
            <v-col cols="8">
              <v-text-field
                type="number"
                v-model="voucher2Amount"
                :label="$t('common.amountOfMoney')"
                @keydown="checkNumber"
                :rules="rules.number"
                :disabled="isReported"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="voucher2Count"
                :items="listItemCount"
                :label="$t('common.numberOfSheets')"
                hide-details
                :disabled="isReported"
                dense
              >
              </v-select>
            </v-col>
          </div>
          <div class="d-flex pd-12">
            <v-col cols="8">
              <v-text-field
                type="number"
                v-model="voucher3Amount"
                :label="$t('common.amountOfMoney')"
                @keydown="checkNumber"
                :rules="rules.number"
                :disabled="isReported"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="voucher3Count"
                :items="listItemCount"
                :label="$t('common.numberOfSheets')"
                :disabled="isReported"
                hide-details
                dense
              >
              </v-select>
            </v-col>
          </div>
          <div class="d-flex pd-12">
            <v-col cols="8">
              <v-text-field
                type="number"
                v-model="voucher4Amount"
                :label="$t('common.amountOfMoney')"
                @keydown="checkNumber"
                :rules="rules.number"
                :disabled="isReported"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="voucher4Count"
                :items="listItemCount"
                :disabled="isReported"
                :label="$t('common.numberOfSheets')"
                hide-details
                dense
              >
              </v-select>
            </v-col>
          </div>
          <div class="d-flex pd-12">
            <v-col cols="8">
              <v-text-field
                type="number"
                v-model="voucher5Amount"
                :label="$t('common.amountOfMoney')"
                @keydown="checkNumber"
                :rules="rules.number"
                :disabled="isReported"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="voucher5Count"
                :disabled="isReported"
                :items="listItemCount"
                :label="$t('common.numberOfSheets')"
                hide-details
                dense
              >
              </v-select>
            </v-col>
          </div>
          <div class="d-flex pd-12">
            <v-col cols="8">
              <v-combobox
                :disabled="isReported"
                :rules="rules.check1stName"
                :value="check1stName"
                @change="check1stName = $event"
                :items="staffList"
                item-text="name"
                item-value="name"
                :label="'1次確認者'"
                dense
              >
              </v-combobox>
            </v-col>
          </div>
          <div class="d-flex pd-12">
            <v-col cols="8">
              <v-combobox
                :disabled="isReported"
                :rules="rules.check2ndName"
                @change="check2ndName = $event"
                :value="check2ndName"
                item-text="name"
                item-value="name"
                :items="staffList"
                :label="'2次確認者'"
                dense
              >
              </v-combobox>
            </v-col>
          </div>
          <v-card-text class="d-flex flex-row-reverse mt-4">
            <div>
              <v-btn color="#C92D2D" class="white--text" @click="closeDialog">
                <v-icon class="mr-1"> mdi-close </v-icon>
                {{ $t("buttons.close") }}
              </v-btn>
              <v-btn
                color="#1873D9"
                @click="confirmFacilityDailyReport"
                :disabled="isReported"
                class="white--text ml-2"
              >
                {{ $t("buttons.save") }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { CONFIRM_FACILITY_DAILY_REPORT_DETAIL } from '@/api/graphql/guestRoomDailyReport/get-recorded-income-facility-day'
import { STAFF_LIST } from '@/api/graphql/staff/staff-info.js'
import { MAX_INT } from '@/constants/enum'
import { mapMutations } from 'vuex'
import { formatCurrency } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  name: 'VoucherCheckDialog',
  data () {
    return {
      listItemCount: [],
      staffList: '',
      amount: null,
      check1stName: '',
      check2ndName: '',
      voucher1Amount: null,
      voucher1Count: null,
      voucher2Amount: null,
      voucher2Count: null,
      voucher3Amount: null,
      voucher3Count: null,
      voucher4Amount: null,
      voucher4Count: null,
      voucher5Amount: null,
      voucher5Count: null,
      rules: {
        check1stName: [
          v => !!v || this.$t('rules.isRequired'),
          v => v !== this.check2ndName || this.$t('rules.duplicate')
        ],
        check2ndName: [
          v => !!v || this.$t('rules.isRequired'),
          v => v !== this.check1stName || this.$t('rules.duplicate')
        ],
        number: [v => v < MAX_INT || this.$t('rules.isInvalid')]
      },
      selectData: '今津康平',
      items: ['ad', 'da', 'adad']
    }
  },
  props: {
    isReported: Boolean,
    visible: Boolean,
    facilitySelected: Number,
    date: String,
    itemReport: Object,
    fromList: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    voucherName () {
      return this.itemReport.name
    }
  },

  watch: {
    visible () {
      this.getData()
    }
  },

  mounted () {
    this.getData()
    this.getStafflist()
    this.getListItemCount()
  },

  methods: {
    formatCurrency,
    ...mapMutations(['setErrorDialog', 'setAlertSuccess', 'setLoadingOverlayShow', 'setLoadingOverlayHide']),
    checkNumber (evt) {
      if (['e', 'E', '+', '-'].includes(evt.key)) {
        return evt.preventDefault()
      }
    },
    async getStafflist () {
      await this.$apollo
        .query({
          query: gql`${STAFF_LIST}`,
          variables: {
            showDisabled: null
          }
        })
        .then(data => {
          this.staffList = data.data.staffList
        })
        .catch(error => {
          console.log(error)
        })
    },
    getData () {
      this.amount = this.itemReport.amount
      this.check1stName = this.itemReport.detail?.check1stName ?? null
      this.check2ndName = this.itemReport.detail?.check2ndName ?? null
      this.voucher1Amount = this.itemReport.detail?.voucher1Amount ?? null
      this.voucher1Count = this.itemReport.detail?.voucher1Count ?? null
      this.voucher2Amount = this.itemReport.detail?.voucher2Amount ?? null
      this.voucher2Count = this.itemReport.detail?.voucher2Count ?? null
      this.voucher3Amount = this.itemReport.detail?.voucher3Amount ?? null
      this.voucher3Count = this.itemReport.detail?.voucher3Count ?? null
      this.voucher4Amount = this.itemReport.detail?.voucher4Amount ?? null
      this.voucher4Count = this.itemReport.detail?.voucher4Count ?? null
      this.voucher5Amount = this.itemReport.detail?.voucher5Amount ?? null
      this.voucher5Count = this.itemReport.detail?.voucher5Count ?? null
    },

    async confirmFacilityDailyReport () {
      if (this.$refs.form.validate()) {
        if (
          ((this.voucher1Amount >= 0 && this.voucher1Count >= 0) ||
            (!this.voucher1Amount && !this.voucher1Count)) &&
          ((this.voucher2Amount >= 0 && this.voucher2Count >= 0) ||
            (!this.voucher2Amount && !this.voucher2Count)) &&
          ((this.voucher3Amount >= 0 && this.voucher3Count >= 0) ||
            (!this.voucher3Amount && !this.voucher3Count)) &&
          ((this.voucher4Amount >= 0 && this.voucher4Count >= 0) ||
            (!this.voucher4Amount && !this.voucher4Count)) &&
          ((this.voucher5Amount >= 0 && this.voucher5Count >= 0) ||
            (!this.voucher5Amount && !this.voucher5Count))
        ) {
          this.setLoadingOverlayShow()
          await this.$apollo
            .mutate({
              mutation: gql`${CONFIRM_FACILITY_DAILY_REPORT_DETAIL}`,
              variables: {
                data: {
                  facilityId: parseInt(this.facilitySelected),
                  reportDate: this.date,
                  paymentMethodId: parseInt(this.itemReport.paymentMethodId),
                  check1stName: this.check1stName.name ?? this.check1stName,
                  check2ndName: this.check2ndName.name ?? this.check2ndName,
                  voucher1Amount: parseInt(this.voucher1Amount),
                  voucher1Count: this.voucher1Count,
                  voucher2Amount: parseInt(this.voucher2Amount),
                  voucher2Count: this.voucher2Count,
                  voucher3Amount: parseInt(this.voucher3Amount),
                  voucher3Count: this.voucher3Count,
                  voucher4Amount: parseInt(this.voucher4Amount),
                  voucher4Count: this.voucher4Count,
                  voucher5Amount: parseInt(this.voucher5Amount),
                  voucher5Count: this.voucher5Count
                },
                byHq: !!this.fromList
              }
            })
            .then(data => {
              this.setLoadingOverlayHide()
              this.setAlertSuccess(this.$t('messages.successfulUpdate'))
              this.$emit('reloadData')
              this.$emit('close')
            })
            .catch(error => {
              this.setLoadingOverlayHide()
              this.setErrorDialog(error)
            })
        } else {
          this.setErrorDialog('クーポンの数量と価格は両方とも利用可能であるか、空である必要があります')
        }
      }
    },

    getListItemCount () {
      for (let i = 0; i <= 100; i++) {
        this.listItemCount.push(i)
      }
    },

    closeDialog () {
      this.$refs.form.resetValidation()
      this.getData()
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-select__selection--comma {
    color: #444444 !important
  }
  .theme--light.v-input textarea {
    border: 2px solid rgba(0, 0, 0, 0.16) !important;
    border-radius: 7px;
  }
  .v-label {
    color: #000000;
    font-size: 10px;
  }
  .v-icon {
    color: #333333;
  }
  .v-input__append-inner * {
    color: #333333 !important;
  }
}
.pd-12 {
  padding: 0 12px;
}
.title-dialog {
  color: #000000;
  font-size: 22px !important;
  font-weight: bold;
}
.cus-title-item {
  font-size: 10px;
  color: #000000 !important;
}
.custom-value-input {
  font-size: 14px;
  color: #444444 !important;
}
</style>
