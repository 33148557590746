export const listContractBackground = {
  DP: 'bg-contract--dp',
  MW: 'bg-contract--mw',
  PW: 'bg-contract--pw',
  FP: 'bg-contract--fp',
  SP: 'bg-contract--sp', // SP  -->  SP(PWポイント含む) => using 'includes' to check
  VM: 'bg-contract--vm',
  MO: 'bg-contract--mo',
  VS: 'bg-contract--vs',
  OTA: 'bg-contract--ota',
  体験宿泊: 'bg-contract--experience',
  ポイント提携施設: 'bg-contract--point-facility',
  優待券招待券: 'bg-contract--free-ticket',
  法人チケット: 'bg-contract--corporate-ticket',
  その他予約: 'bg-contract--other-reservations',
  ブロック: 'bg-contract--block',
  排他制御: 'bg-contract--exclusive-control',
  PARKINGBLOCK: 'bg-contract--parking-block'
}

export const listContractColor = {
  DP: 'var(--contract__dp))',
  MW: 'var(--contract__mw)',
  PW: 'var(--contract__pw)',
  FP: 'var(--contract__fp)',
  SP: 'var(--contract__sp)', // SP  -->  SP(PWポイント含む) => using 'includes' to check
  VM: 'var(--contract__vm)',
  MO: 'var(--contract__mo)',
  VS: 'var(--contract__vs)',
  OTA: 'var(--contract__ota)',
  体験宿泊: 'var(--contract__experience)',
  ポイント提携施設: 'var(--contract__point-facility)',
  優待券招待券: 'var(--contract__free-ticket)',
  法人チケット: 'var(--contract__corporate-ticket)',
  その他予約: 'var(--contract__other-reservations)',
  ブロック: 'var(--contract__block)',
  排他制御: 'var(--contract__exclusive-control)',
  PARKINGBLOCK: 'var(--contract__parking-block)'
}

export function getBackgroundColorOfBooking (booking) {
  if (booking.blockRoom) return listContractBackground.ブロック

  switch (booking.bookingTypeId ?? booking.bookingType.id) {
    case 1: return listContractBackground.SP
    case 2: return listContractBackground.FP
    case 3: return listContractBackground.DP
    case 4: return listContractBackground.優待券招待券
    case 5: return listContractBackground.VS
    case 6: return listContractBackground.MO
    case 7: return listContractBackground.VM
    case 8: return listContractBackground.OTA
    case 9: return listContractBackground.PW
    case 10: return listContractBackground.MW
    case 11: return listContractBackground.その他予約
    case 12: return listContractBackground.ポイント提携施設
    case 13: return listContractBackground.体験宿泊
  }
  return listContractBackground.その他予約
}

export function getBackgroundColorOfBookingTypeId (bookingTypeId) {
  switch (bookingTypeId) {
    case 1: return listContractBackground.SP
    case 2: return listContractBackground.FP
    case 3: return listContractBackground.DP
    case 4: return listContractBackground.優待券招待券
    case 5: return listContractBackground.VS
    case 6: return listContractBackground.MO
    case 7: return listContractBackground.VM
    case 8: return listContractBackground.OTA
    case 9: return listContractBackground.PW
    case 10: return listContractBackground.MW
    case 11: return listContractBackground.その他予約
    case 12: return listContractBackground.ポイント提携施設
    case 13: return listContractBackground.体験宿泊
  }
  return listContractBackground.その他予約
}
