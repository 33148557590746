<template>
  <v-select
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    :items="items"
    :value="value"
    item-text="name"
    item-value="value"
    class="cleaning-select"
  >
  </v-select>
</template>

<script>
export default {
  props: {
    value: String
  },
  computed: {
    items () {
      return [
        {
          name: 'あり',
          value: 'Yes'
        },
        {
          name: 'なし',
          value: 'No'
        }
      ]
    }
  }
}
</script>
