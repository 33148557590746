<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog" width="600">
      <v-form ref="form">
        <v-card class="pa-2">
          <v-card-title>
            <span class="title-dialog">
             {{ itemReport.name }} クレジットカード チェック
            </span>
          </v-card-title>
          <v-card-text class="mt-4">
            <v-col :cols="4">
              <v-row>
                <span class="text-size-normal cus-title-item">{{
                  $t("reports.amountOfMoney")
                }}</span>
              </v-row>
              <v-row>
                <span class="pt-2 custom-value-input"> {{ formatCurrency(amount) }}</span>
              </v-row>
            </v-col>
            <v-file-input
              clear-icon=""
              :rules="rules.importImgs"
              accept="image/png, image/jpeg, image/bmp"
              class="mt-4"
              :disabled="isReported"
              @change="importImg"
              :label="isImportFile ? getMutilFile() : '' "
              multiple
              chips
              prepend-icon="mdi-camera"
              :messages="$messages.fileLimit({multiple: true})"
            />
            <v-col :cols="6">
              <v-combobox
                :disabled="isReported"
                class="pb-0"
                :rules="rules.check1stName"
                :value="itemReport.detail && itemReport.detail.check1stName || ''"
                @change="check1stName = $event"
                :items="staffList"
                item-text="name"
                item-value="name"
                :label="$t('reports.firstConfirmer')"
                color="var(--text__gray)"
              />
              <v-combobox
                :disabled="isReported"
                class="pb-0"
                :rules="rules.check2ndName"
                :value="itemReport.detail && itemReport.detail.check2ndName || ''"
                @change="check2ndName = $event"
                :items="staffList"
                item-text="name"
                item-value="name"
                :label="$t('reports.secondConfirmer')"
                color="var(--text__gray)"
              />
            </v-col>
          </v-card-text>
          <v-card-text class="d-flex justify-end align-center">
            <v-btn
              color="var(--bt__red)"
              class="mr-3 d-flex align-center btn_status white--text"
              @click="closeDialog"
            >
              <v-icon class="white--text"> mdi-close </v-icon>
              {{ $t("common.close") }}
            </v-btn>
            <v-btn
              class="white--text"
              color="var(--bt__blue)"
              :disabled="isReported"
              @click="updateData"
            >
              {{ $t("common.save") }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  CONFIRM_FACILITY_DAILY_REPORT_DETAIL,
  ATTACH_FILE_FACILITY_DAILY_REPORT_DETAIL
} from '@/api/graphql/guestRoomDailyReport/get-recorded-income-facility-day'
import { GENERATE_UPLOAD_URL } from '@/api/graphql/csv/uploadCsvAccommodationPrice'
import { STAFF_LIST } from '@/api/graphql/staff/staff-info.js'
import axios from 'axios'
import { mapMutations } from 'vuex'
import { formatCurrency, handlError, getCopyErrorTextView } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  name: 'checkCreditCardDialog',
  props: {
    isReported: Boolean,
    visible: Boolean,
    title: String,
    day: String,
    facilitySelected: Number,
    date: String,
    itemReport: Object,
    fromList: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  data () {
    return {
      check1stName: '',
      check2ndName: '',
      isImportFile: true,
      isUploadSuccess: false,
      imgUpload: [],
      staffList: [],
      attachmentFile: '',
      amount: null,
      rules: {
        check1stName: [(v) => v?.length > 0 || this.$t('rules.isRequired')
        ],
        check2ndName: [(v) => v?.length > 0 || this.$t('rules.isRequired')
        ],
        importImgs: [
          value =>
            (value?.length < 3 && this.checkSize(value)) ||
            !value ||
            this.$t('rules.isInvalid') // || value.reduce((size, file) => size + file.size, 0) < 2000000 ||
        ]
      },
      primaryConfirmer: '今津康平',
      secondaryConfirmer: '今津康平'
    }
  },

  watch: {
    visible () {
      this.getData()
    }
  },

  mounted () {
    this.getData()
    this.getStafflist()
  },

  methods: {
    formatCurrency,
    getCopyErrorTextView,
    ...mapMutations(['setCopyErrorText', 'setErrorDialog', 'setAlertSuccess', 'setLoadingOverlayShow', 'setLoadingOverlayHide']),
    async importImg (v) {
      if (v?.length > 0) {
        for (let i = 0; i < v.length; i++) {
          if (v[i].size < 10485760) {
            await this.$apollo
              .mutate({
                mutation: GENERATE_UPLOAD_URL,
                variables: {
                  type: 'facilityDailyReport/attachments',
                  contentType: v[i].type,
                  contentLength: v[i].size
                }
              })
              .then(data => {
                this.imgUpload.push({
                  url: data.data.generateUploadUrl.uploadUrl,
                  key: data.data.generateUploadUrl.key,
                  fileUrl: data.data.generateUploadUrl.fileUrl,
                  data: v[i]
                })
                const uploadUrl = data.data.generateUploadUrl.uploadUrl
                axios.put(
                  uploadUrl,
                  v[i],
                  {
                    headers: {
                      'Content-Type': v[i].type
                    }
                  })
                this.isImportFile = false
              })
              .catch(error => {
                console.log(error)
                this.isImportFile = true
              })
          }
        }
      } else {
        this.isImportFile = true
      }
    },
    async getStafflist () {
      await this.$apollo
        .query({
          query: gql`${STAFF_LIST}`,
          variables: {
            showDisabled: null
          }
        })
        .then((data) => {
          this.staffList = [...data.data.staffList.map(item => item.name)]
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getData () {
      this.amount = this.itemReport.amount
      if (this.itemReport.detail) {
        this.check1stName = this.itemReport.detail.check1stName
        this.check2ndName = this.itemReport.detail.check2ndName
      }
    },

    getMutilFile () {
      if (this.itemReport.detail) {
        const arrFilename = []
        for (let i = 0; i < this.itemReport.detail.attachments.length; i++) {
          arrFilename.push(this.itemReport.detail.attachments[i].attachmentFile)
        }
        return arrFilename.join(',')
      }
    },
    async putImg () {
      if (this.imgUpload?.length > 0) {
        for (let i = 0; i < this.imgUpload.length; i++) {
          await axios
            .put(
              this.imgUpload[i].url,
              this.imgUpload[i].data,
              {
                headers: {
                  'Content-Type': this.imgUpload[i].data.type
                }
              }
            )
            .then(data => {
              this.isUploadSuccess = true
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
    async confirmFacilityDailyReport () {
      if (this.$refs.form.validate()) {
        this.setLoadingOverlayShow()
        const variables = {
          data: {
            facilityId: parseInt(this.facilitySelected),
            reportDate: this.date,
            paymentMethodId: parseInt(this.itemReport.paymentMethodId),
            check1stName: this.check1stName,
            check2ndName: this.check2ndName
          },
          byHq: !!this.fromList
        }
        await this.$apollo
          .mutate({
            mutation: gql`${CONFIRM_FACILITY_DAILY_REPORT_DETAIL}`,
            variables: variables
          })
          .then(async data => {
            this.setLoadingOverlayHide()
            await this.$emit('reloadData')
            this.setAlertSuccess(this.$t('messages.successfulUpdate'))
            this.$emit('close')
          })
          .catch(error => {
            this.setLoadingOverlayHide()
            this.setCopyErrorText(this.getCopyErrorTextView(CONFIRM_FACILITY_DAILY_REPORT_DETAIL, variables, error.graphQLErrors))
            handlError(this.$store.commit, error.graphQLErrors)
          })
      }
    },
    async updateData () {
      await this.updateImgs()
      setTimeout(() => {
        this.confirmFacilityDailyReport()
      }, 100)
    },
    async updateImgs () {
      if (this.imgUpload?.length > 0) {
        if (this.$refs.form.validate()) {
          this.setLoadingOverlayShow()
          await this.putImg()
          for (let i = 0; i < this.imgUpload.length; i++) {
            const variables = {
              facilityId: parseInt(this.facilitySelected),
              reportDate: this.date,
              paymentMethodId: parseInt(this.itemReport.paymentMethodId),
              fileUrl: this.imgUpload[i].fileUrl,
              byHq: !!this.fromList
            }
            await this.$apollo.mutate({
              mutation: gql`${ATTACH_FILE_FACILITY_DAILY_REPORT_DETAIL}`,
              variables
            }).then(data => {
              this.setLoadingOverlayHide()
            }).catch(error => {
              this.setLoadingOverlayHide()
              this.setCopyErrorText(this.getCopyErrorTextView(ATTACH_FILE_FACILITY_DAILY_REPORT_DETAIL, variables, error.graphQLErrors))
              handlError(this.$store.commit, error.graphQLErrors)
              throw error
            })
          }
        }
      }
    },

    checkSize (v) {
      if (v) {
        for (let i = 0; i < v.length; i++) {
          if (v[i].size > 10485760) {
            return false
          }
        }
      }
      return true
    },

    save () {
      this.$emit('close')
    },
    closeDialog () {
      this.$refs.form.resetValidation()
      this.getData()
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-select--is-multi {
      .v-select__selection {
        color: #444444 !important
      }
  }
  .theme--light.v-input textarea {
    border: 2px solid rgba(0, 0, 0, 0.16) !important;
    border-radius: 7px;
  }
  .v-file-input__text--chips {
    background-color: #f0f0f0 !important;
  }
  .cus-title-item .theme--light {
    font-size: 10px;
    color: #000000;
  }
  .cus-title-item .v-select__selection--comma {
    font-size: 21px !important;
  }
  .mdi-camera {
    color: #757575;
  }
  .theme--light {
    font-size: 15px;
  }
  .theme--light.v-input input {
    color: #444444;
  }
  .v-btn {
    font-size: 14px !important;
  }
  .v-input__append-inner * {
    color: #333333 !important;
  }
  .v-file-input input::placeholder {
    font-size: 15px !important;
    color: #606060;
  }
}

.title-dialog {
  font-size: 22px !important;
  font-weight: bold;
  color: #000000;
}
.cus-title-item {
  font-size: 10px;
  color: #000000 !important;
}
.custom-value-input {
  font-size: 14px;
  color: #444444 !important;
}
</style>
