import gql from 'graphql-tag'

export const UPLOAD_CSV_ACCOMMODATION_PRICE = gql`
  mutation(
    $scheduledAt: DateTime
    $file: CSV!
  ) {
    uploadCsvAccommodationPrice(
      data: { file: $file, scheduledAt: $scheduledAt }
    ) {
      scheduled
    }
  }
`

// TEST ------------
export const GENERATE_UPLOAD_URL = gql`mutation(
  $type: String!
  $contentType: String!
  $contentLength: Int!
) {
  generateUploadUrl(
    type: $type
    contentType: $contentType
    contentLength: $contentLength
  ) {
    uploadUrl
    key #このKeyをプランの価格設定時に送ること
    fileUrl
  }
}`

export const UPLOAD_CSV_ACC_PRICE = gql`
mutation ($data: AccommodationPricesCSVUploadInput! ) { 
  uploadCsvAccommodationPrice(data: $data) 
  { scheduled 
  } 
}
`
export const NONE_PRODUCT_LIST = gql`
query {
  nonProductList{id name}
}
`

export const UPLOAD_CSV_NONE_PRODUCT_PRICE = gql`
mutation (
  $data: NonProductPricesCSVUploadInput!
) {
  uploadCsvNonProductPrice(data: $data)
}
`

export const CANCEL_CSV_UPDATE = gql`
mutation ($id: Int!) {
  cancelScheduledAccommodationPrice (id: $id)
}
`
